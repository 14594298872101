import React, { Component } from 'react';
import MyGroceryElement from '../components/myGroceryList/MyGroceryElement';
import PageTitle from 'components/common/PageTitle';
import { Scrollbars } from "react-custom-scrollbars";
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col
} from 'reactstrap';

import axios from 'axios';
import '../assets/css/custom.css';

import { idCampaignName, myGroceryListName } from '../helper/constant';
import { serverResponse } from '../util/fakeServer';

class MyGroceryList extends Component {

    state = {
        list: []
    }

    componentDidMount() {


        var id = this.props.match.params.id;

        //axios per il caricamento della lista
        axios({
            method: 'get',
            url: 'https://www.wipdev.it/listaspesaapi/leaflet/v1/api/read?id=' + id,
            headers: {
            },
        })
            .then(res => {
                let tmpProducts = res.data;
                tmpProducts.sort((a, b) => {
                    const [aDone, bDone] = [a.done, b.done];
                    if (aDone === bDone) {
                        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                        if (aID === -1) return +1
                        if (bID === -1) return -1
                        return aID - bID
                    };
                    if (aDone === true) return +1;
                    if (bDone === true) return -1;
                    return 1;
                });

                this.setState({
                    list: tmpProducts
                });
            }).catch(error => {
                console.log(error);
            });
    }

    checkProduct = (e) => {

        //TODO
        //axios per l'incremento del counter 'done' del prodotto
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {

            }).catch(error => {
                //console.log(error);
            });*/
        let tmpProducts = this.state.list;
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        this.setState({
            list: tmpProducts
        });
        
    }

    sortItems = (tmpProducts, e) => {
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        return tmpProducts;
    }

    backToLeaflet = () => {
        localStorage.setItem(myGroceryListName + serverResponse.leaflet.code, JSON.stringify(this.state.list));
        localStorage.setItem(idCampaignName, (this.state.list ? this.state.list[0].id_campagna : ''));
        window.location = "/leaflet";
    }

    render() {
        let tot = 0;
        const items = this.state.list;
        items.map((element, key) => {
            tot += parseFloat(element.currentPrice);
            element.index = key;
            element.images = [];
            element.images.push({ "image_file": element.image_file });
            return null;
        });
        tot = tot.toFixed(2);

        return (
            <div className="custom-container" style={{ overflow: "hidden", height: "100vh" }}>
                <MetaTags>
                    <title>La mia lista della spesa</title>
                    <meta property="og:url" content={serverResponse.config.serverPath + "/myGroceryList"} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Lista della spesa | Volantino Interattivo" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="./logo.png" />
                </MetaTags>

                <PageTitle title="myGroceryList" className="ml-15" />

                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                        <a style={{ textDecoration: "none" }} href={"/#"} onClick={this.backToLeaflet}>
                            <Row className="custom-grocery-back-btn">
                                <span style={{ textAlign: "center", width: "100%" }}>TORNA AL VOLANTINO</span>
                            </Row>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Scrollbars
                            autoHide
                            ref="scrollbars"
                            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                            className="custom-my-grocery-scrollbar">

                            {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)}

                        </Scrollbars>
                    </Col>
                </Row>
                <Row style={{
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    left: "0",
                    marginLeft: "unset !important",
                    marginRight: "unset !important"
                }}>
                    <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                        <Row className="custom-grocery-back-btn" style={{ cursor: "auto", marginBottom: "0" }}>
                            <span style={{ textAlign: "center", width: "100%" }}>Totale: € {tot}</span>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default MyGroceryList;