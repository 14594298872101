import React, { Component } from 'react';

import { Scrollbars } from "react-custom-scrollbars";
import {
    Popover,
    PopoverHeader,
    PopoverBody,
    Input,
    Tooltip
} from "reactstrap";

import { connect } from 'react-redux';
import actions from '../../redux/groceryList/action';
import GroceryProduct from './GroceryProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faTimes,
    faPlusCircle,
    faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import ShareGroceryList from '../share/ShareGroceryList';
import AddProd from '../../assets/images/icons/add_prod.svg';
import ClearChart from '../../assets/images/icons/clear_chart.png';
import { strings } from '../../util/data/strings';

class GroceryList extends Component {

    state = {
        newProductTitle: "",
        newProductPrice: "",
        newProductQuantity: 1,
        saveTooltipOpen: false,
        deleteAllTooltipOpen: false,
    }

    handleChangeTitle = (e) => {
        this.setState({ newProductTitle: e.target.value });
    }

    handleChangePrice = (e) => {
        this.setState({ newProductPrice: e.target.value });
    }

    handleKeyUp = (event) => {
        var keycode = event.which;
        if (!(event.shiftKey === false && (keycode === 46 || keycode === 8 || keycode === 37 || keycode === 39 || (keycode >= 48 && keycode <= 57)))) {
            event.preventDefault();
        }
    }

    handleChangeQuantity = (type) => {
        let newQuantity;
        if (type === 1) { // increment
            newQuantity = this.state.newProductQuantity + 1;
        } else { //decrement
            if (this.state.newProductQuantity > 1) {
                newQuantity = this.state.newProductQuantity - 1;
            } else {
                newQuantity = this.state.newProductQuantity;
            }
        }
        this.setState({ newProductQuantity: newQuantity });
    }

    saveNewProduct = () => {
        let {
            newProductTitle,
            newProductPrice,
            newProductQuantity
        } = this.state;

        if (newProductTitle === "" || newProductPrice === 0) return;

        this.props.addProduct({
            item_id: Date.now() % 10,
            description: "",
            field1: newProductTitle,
            filed2: "",
            filed3: "",
            filed4: "",
            grocery_label: "pz.",
            images: [
                {
                    "image_file": "./prodotto.png",  
                    "cropped": false
                }
            ],
            price: newProductPrice,
            price_label: "€" + newProductPrice,
            quantity: newProductQuantity,
            equivalence: 1,
            quantity_step: 1,
            done: 0,
            variety: undefined,
        });

        this.setState({
            newProductTitle: "",
            newProductPrice: "",
            newProductQuantity: 1,
        })

    }

    toggleSaveTooltip = () => {
        this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen })
    }

    toggleDeleteAllTooltip = () => {
        this.setState({ deleteAllTooltipOpen: !this.state.deleteAllTooltipOpen })
    }

    render() {

        const {
            newProductTitle,
            newProductPrice,
            newProductQuantity
        } = this.state;

        var totPrice = 0;
        let list = this.props.groceryList.list.map((product, key) => {
            //totPrice += parseFloat(product.price) * parseInt(product.quantity);
            totPrice += parseFloat(product.currentPrice);
            return <GroceryProduct key={key} id={product.item_id} title={product.field1} variety={product.variety} price={product.currentPrice} grocery_label={product.grocery_label} quantity={product.quantity} quantity_step={product.quantity_step} done={product.done} images={product.images} />
        });

        return (
            <Popover
                className="header-popover-width custom-popover"
                isOpen={this.props.isOpen}
                target={this.props.target}
                placement={this.props.placement}
                toggle={this.props.toggle}
                trigger="block"
                boundariesElement="window"
            >
                <PopoverHeader
                    className="custom-popover-header text-center"
                >
                    <ShareGroceryList />
                    <span>La tua lista della spesa</span>
                    <FontAwesomeIcon icon={faTimes} onClick={this.props.toggle} />
                </PopoverHeader>
                <PopoverBody className="pa-0">
                    <div>
                        <Scrollbars
                            //style={{ height: "500px" }}
                            className="custom-scrollbars"
                            autoHide
                        >

                            {
                                list
                            }

                        </Scrollbars>

                        {/*Insert by hand*/}
                        <ul className="list-inline pa-10 ma-0 ul-bottom-border flex custom-popover-footer custom-total-price">
                            <li className="list-inline-item">
                                <h5>Totale: € {totPrice.toFixed(2)}</h5>
                            </li>
                            <li style={{ marginLeft: "auto" }}>
                                {/*<FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} onClick={() => this.props.deleteAll()} />*/}
                                <img id="btnDeleteAll" src={ClearChart} style={{ color: "#00ff00", marginLeft: "20px", width: "30px", cursor: "pointer" }} onClick={() => this.props.deleteAll()} alt="img" />
                                <Tooltip placement="top" isOpen={this.state.deleteAllTooltipOpen} target="btnDeleteAll" toggle={this.toggleDeleteAllTooltip}>
                                    {strings.deleteAllBtnTooltip}
                                </Tooltip>
                            </li>
                        </ul>
                        <ul className="list-inline pa-10 ma-0 ul-bottom-border flex custom-popover-footer">
                            <li className="list-inline-item pa-5 mobile-grocery-product-container">
                                <h5>
                                    <Input type="text" name="product-title" id="product-title" placeholder="Nome prodotto" value={newProductTitle} onChange={this.handleChangeTitle} /> <br />
                                    <Input
                                        type="number"
                                        name="product-price"
                                        id="product-price"
                                        placeholder="Prezzo"
                                        style={{ marginTop: "-20px" }}
                                        value={newProductPrice}
                                        onChange={this.handleChangePrice}
                                        onKeyPress={this.handleKeyUp}
                                    />
                                </h5>
                                <div className="grocery-product-quantity">
                                    <FontAwesomeIcon icon={faMinusCircle} className="change-product-quantity-icon" style={{ marginRight: "10px"}} onClick={() => this.handleChangeQuantity(0)} />
                                    <h6>{newProductQuantity}</h6>
                                    <FontAwesomeIcon icon={faPlusCircle} className="change-product-quantity-icon" style={{ marginLeft: "10px"}} onClick={() => this.handleChangeQuantity(1)} />

                                    {/*<FontAwesomeIcon icon={faSave} style={{ color: "#00ff00", marginLeft: "20px" }} onClick={() => this.saveNewProduct()} />*/}
                                    <img id="btnSave" src={AddProd} style={{ color: "#00ff00", marginLeft: "20px", width: "30px", cursor: "pointer" }} onClick={() => this.saveNewProduct()} alt="img" />
                                    <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target="btnSave" toggle={this.toggleSaveTooltip}>
                                        {strings.saveBtnTooltip}
                                    </Tooltip>
                                </div>
                            </li>
                        </ul>
                    </div>
                </PopoverBody>
            </Popover>
        );
    }
}

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

function mapDispatchToProps(dispatch) {
    return {
        addProduct: (newProduct) => {
            dispatch(actions.addProduct(newProduct));
        },
        deleteAll: () => {
            dispatch(actions.deleteAll());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryList);